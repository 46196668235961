import React, { useEffect, useRef } from 'react'
import styled, { keyframes } from 'styled-components'
import { FaArrowDown } from 'react-icons/fa'
import {
  Link,
  Element,
  Events,
  animateScroll as scroll,
  scrollSpy,
  scroller,
} from 'react-scroll'
import { Spring } from 'react-spring'

import VideoWebm from '../images/back.webm'
import VideoMp4 from '../images/back.mp4'

import VideoBack from '../images/back-lamp.jpg'

const bounce = keyframes`
  0% {
    transform: translateY(-10px);
  }
  
  100% {
    transform: translateY(10px);
    
  }
`

const borderFade = keyframes`
  0% {
    border-left: 0px solid crimson;
  }
  100% {
    border-left: 14px solid crimson;

  }
`

const fadeIn = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`

const HeaderWrapper = styled.div`
  display: grid;
  grid-template-rows: 100vh;
  grid-template-columns: 1fr;
  background: url(${VideoBack});
  background-size: cover;
  min-height: 100vh;
  overflow: hidden;
  font-family: 'Nunito Sans', sans-serif;

  @media (min-width: 901px) {
    background: none;
  }

  h1 {
    margin: 0;
  }

  h2 {
    margin: 0;
    font-size: 7.2rem;
    font-weight: 700;
    color: ghostwhite;
    opacity: 0.9;
    max-width: 50rem;
    line-height: 1;
    text-align: left;
    padding: 0.1rem 2rem 0.1rem 1.7rem;
    border-left: 12px solid crimson;

    @media (max-width: 900px) {
      font-size: 4.4rem;
      border-left: none;
      text-align: center;
      padding: 0;
    }
  }

  video {
    grid-area: 1 / 1 / -1 / -1;
    min-width: 100%;
    min-height: 100%;
    object-fit: cover;
    background-size: cover;
    transition: 1s opacity;
    z-index: -1;
    overflow: hidden;

    @media (max-width: 900px) {
      display: none;
    }
  }

  button {
    border: none;
    background: crimson;
    color: #fff;
    padding: 1em 2.5em;
    font-size: 1.2rem;
    transition: all 0.3s ease-in-out;
    border-radius: 3px;
    border-bottom: 7px solid darkred;

    &:hover {
      background: #fff;
      color: #000;
      cursor: pointer;
      border-bottom: 7px solid black;
    }
  }
`

const HeaderContainer = styled.div`
  grid-area: 1 / 1 / -1 / -1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  background-color: rgba(0, 0, 0, 0.6);
`

const HeaderContent = styled.div`
  /* background: rgba(0, 0, 0, 0.1); */
  padding: 0.1rem 2rem 0.1rem 1.75rem;
  display: flex;
  grid-template-columns: 1fr;
  align-items: center;
  /* margin: auto; */
  justify-content: start;
  width: 80%;
  /* max-width: 500px; */
  /* animation: 0.3s ${borderFade} 2.2s ease-in-out forwards; */

  @media (max-width: 900px) {
    padding: 1rem;
    border: none;
  }
`

const HeaderArrow = styled.div`
  display: grid;
  align-items: flex-end;
  position: absolute;
  color: #fff;
  font-size: 3.8rem;
  padding-bottom: 5rem;
  bottom: 0;
  opacity: 0;
  animation: 1.5s ${bounce} ease-in-out alternate infinite,
    0.3s ${fadeIn} 0.3s ease-in-out forwards;

  &:hover {
    cursor: pointer;
  }

  @media (max-width: 1200px) {
    display: none;
  }
`

export default function Header() {
  return (
    <HeaderWrapper>
      <video id="bgvid" playsInline autoPlay muted loop>
        <source src={VideoWebm} type="video/webm" />

        <source src={VideoMp4} type="video/mp4" />
      </video>
      <HeaderContainer>
        <HeaderContent>
          <Spring
            from={{ opacity: 0, transform: 'translateY(100px)' }}
            to={{ opacity: 1, transform: 'translateY(0)' }}
            config={{ tension: 100, friction: 50 }}
          >
            {styles => (
              <div style={styles}>
                <h2>Création de contenu vidéo</h2>
              </div>
            )}
          </Spring>
        </HeaderContent>
        <HeaderArrow>
          <Link to="About" smooth={true} offset={-30} duration={500}>
            <FaArrowDown />
          </Link>
        </HeaderArrow>
      </HeaderContainer>
    </HeaderWrapper>
  )
}
