import React, { useState, useEffect, useRef } from 'react'
import styled from 'styled-components'
import { FaEnvelope } from 'react-icons/fa'

const FormWrapper = styled.div`
  display: flex;
  flex-direction: column;

  form {
    display: flex;
    flex-direction: column;
  }

  label {
    font-size: 1.1rem;
    color: ghostwhite;
  }

  input,
  textarea {
    border: none;
    padding: 15px;
    font-size: 1.2rem;
    border: 1px solid #e5e5e5;
    width: 100%;
    border-radius: 3px;
    margin-top: 0.5rem;
    font-family: inherit;

    @media (max-width: 900px) {
      padding: 8px;
    }
  }

  button {
    border: none;
    background: crimson;
    color: #fff;
    text-transform: capitalize;
    padding: 1em 2.5em;
    font-size: 1.1rem;
    transition: all 0.3s ease-in-out;
    border-radius: 3px;
    margin-top: 1rem;

    &:hover {
      opacity: 0.9;
      cursor: pointer;
    }
  }
`

const FieldItem = styled.div`
  margin: 1rem 0;
`

const Form = props => {
  const [name, setName] = useState('')
  const [email, setEmail] = useState('')
  const [message, setMessage] = useState('')

  const nameRef = useRef()
  const emailRef = useRef()
  const messageRef = useRef()

  return (
    <FormWrapper>
      <form
        name="Formulaire de contact - Walter Production"
        method="post"
        action="/merci"
        data-netlify="true"
        data-netlify-honeypot="bot-field"
      >
        <input type="hidden" name="bot-field" />
        <input
          type="hidden"
          name="form-name"
          value="Formulaire de contact - Walter Production"
        />
        <FieldItem>
          <label>Votre nom &mdash;</label>
          <input
            type="text"
            name="name"
            onChange={e => setName(e.target.value)}
            value={name}
            ref={nameRef}
            onMouseEnter={() => {
              nameRef.current.focus()
            }}
          />
        </FieldItem>
        <FieldItem>
          <label>Votre courriel &mdash;</label>

          <input
            type="email"
            name="email"
            onChange={e => setEmail(e.target.value)}
            value={email}
            ref={emailRef}
            onMouseEnter={() => {
              emailRef.current.focus()
            }}
          />
        </FieldItem>
        <FieldItem>
          <label>Votre message &mdash;</label>
          <textarea
            name="message"
            onChange={e => setMessage(e.target.value)}
            value={message}
            ref={messageRef}
            onMouseEnter={() => {
              messageRef.current.focus()
            }}
          />
        </FieldItem>
        <button
          type="submit"
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <FaEnvelope style={{ marginRight: '0.6rem' }} />{' '}
          <span style={{ marginTop: '2px' }}>Envoyer</span>
        </button>
      </form>
    </FormWrapper>
  )
}

export default Form
