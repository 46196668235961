import React from 'react'
import * as Scroll from 'react-scroll'
import {
  Link,
  Element,
  Events,
  animateScroll as scroll,
  scrollSpy,
  scroller,
} from 'react-scroll'

import Header from '../components/Header'
import Nav from '../components/nav'
import Layout from '../components/Layout'
import About from '../components/About'
import Clients from '../components/Clients'
import Services from '../components/Services'
import Team from '../components/Team'
import Realisations from '../components/Realisations'
import Contact from '../components/Contact'
import Footer from '../components/Footer'
import Social from '../components/Social'
import Testimonies from '../components/Testimonies'

const Home = () => (
  <Layout>
    <Nav />
    <Header />
    <Testimonies />
    <Element name="About">
      <About />
    </Element>
    <Clients />
    <Element name="Services">
      <Services />
    </Element>
    <Team />
    <Element name="Reals">
      <Realisations />
    </Element>
    <Element name="Contact">
      <Contact />
    </Element>
    <Social />
    <Footer />
  </Layout>
)

export default Home
