import React from 'react'
import styled from 'styled-components'
import Fade from 'react-reveal/Fade'

import Card from './Card'

import Services1 from '../images/icons/teaser.png'
import Services2 from '../images/icons/documentaire.png'
import Services3 from '../images/icons/formation.png'
import Services4 from '../images/icons/Capsule.png'
import bg from '../images/back2.jpg'

const ServicesWrapper = styled.div`
  background: linear-gradient(45deg, rgba(0, 0, 0, 0.75), rgba(0, 0, 0, 0.99)),
    url(${bg});
  background-attachment: fixed;
  background-size: cover;
  font-family: 'Nunito Sans', sans-serif;
  min-height: 50rem;
  margin-top: -2px;
  padding: 8rem 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  -webkit-clip-path: polygon(0 0, 100% 0%, 100% 95%, 0% 100%);
  clip-path: polygon(0 0, 100% 0%, 100% 95%, 0% 100%);

  .services__title {
    margin: 0;
    color: ghostwhite;
    text-align: center;
    font-size: 3.2rem;
    font-weight: 400;
    margin-bottom: 5rem;
    position: relative;
    display: inline-block;
    padding: 0.5rem 0.5rem;
    text-transform: uppercase;
    /* transform: skew(-15deg) rotate(1deg); */
    border-bottom: 3px solid ghostwhite;

    /* &::after {
      content: '';
      display: flex;
      background: black;
      height: 100%;
      width: 100%;
      position: absolute;
      border-radius: 3px;
      left: 0;
      top: 0;
      z-index: -1;
    } */

    @media (max-width: 900px) {
      font-size: 2.8rem;
      margin-bottom: 6rem;
    }
  }

  span {
    font-weight: bold;
  }

  @media (max-width: 1100px) {
    -webkit-clip-path: none;
    clip-path: none;
    padding: 7rem 3rem;
  }
`

const ServicesContainer = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  display: grid;
  display: grid;
  grid-template-columns: repeat(2, minmax(350px, 1fr));
  justify-content: center;
  justify-items: center;
  grid-auto-rows: 1fr;
  grid-gap: 10px;

  @media (max-width: 900px) {
    grid-template-columns: 1fr;
  }
`

const Services = props => {
  return (
    <ServicesWrapper>
      <h2 className="services__title">
        Nos <span>Services</span>
      </h2>
      <Fade>
        <ServicesContainer>
          <Card
            title="Teaser & Pub"
            desc="Court vidéo de style bande-annonce présentant une entreprise ou publicité scénarisée et produite sur mesure pour créer un effet viral afin que la terre entière partage sur les réseaux sociaux."
            icon={Services1}
            hash="#vidéovirale"
          />
          <Card
            title="Documentaire"
            desc="Tournage de la vraie vie. Rien de mieux que ça pour faire passer un message percutant et toucher les gens. Simple, efficace et intègre. Comme Walter."
            icon={Services2}
            hash="#webmarketing"
          />
          <Card
            title="Formation"
            desc="Vidéos pour des cours en ligne de tout genre ou encore outil de formation pour que les 500 062 employés d'une multinationale reçoivent exactement le même briefing."
            icon={Services3}
            hash="#stratégiedecommunication"
          />
          <Card
            title="Capsule"
            desc="Série de capsules informatives sur n'importe quel sujet au monde! N'importe lequel, vraiment. Voyage, inspiration, humour, cuisine, juste une fois au chalet, etc."
            icon={Services4}
            hash="#contenuweb"
          />
        </ServicesContainer>
      </Fade>
    </ServicesWrapper>
  )
}

export default Services
