import React, { useState } from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { FaPlayCircle } from 'react-icons/fa'
import Modal from 'react-modal'

const CardRealWrapper = styled.div`
  /* padding: 1rem 2rem 2rem 2rem; */
`

const CardRealContainer = styled.div`
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2rem;
  border-radius: 3px;
  max-width: 100%;
  height: 100%;
`

const CardRealDesc = styled.div`
  font-size: 1.5rem;
  margin-top: 1rem;
  color: ghostwhite;
  max-width: 35rem;
  margin: 0 auto;

  h2 {
    margin: 0;
    margin-bottom: 0.9rem;
    font-size: 2rem;
    background: rgba(0, 0, 0, 0.9);
    padding: 0.8rem 10px;
    border-radius: 3px;
  }

  h4 {
    margin: 0;
    margin-bottom: 1.2rem;
    font-weight: 600;
    color: crimson;
    background: ghostwhite;
    font-size: 1rem;
    padding: 5px 10px;
    display: inline-block;
    border-radius: 3px;
  }

  p {
    font-size: 1.2rem;
    margin: 0;
    line-height: 1.6;
  }

  .play {
    transition: color 0.3s ease-in-out;
  }

  .play:hover {
    color: crimson;
    cursor: pointer;
  }
`

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    background: 'black',
    border: 'none',
    overflow: 'visible',
  },
  overlay: {
    background: 'rgba(0, 0, 0, 0.8)',
  },
}

const CardReal = ({ title, type, image, url }) => {
  const [modalOpen, setModalOpen] = useState(false)

  const setOpen = () => {
    setModalOpen(true)
  }

  const closeModal = () => {
    setModalOpen(false)
  }

  const back = {
    backgroundImage: `linear-gradient(rgba(0,0,0,0.1), rgba(0,0,0,0.3)), url(${image})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
  }

  return (
    <CardRealWrapper>
      <CardRealContainer style={back}>
        <Modal
          isOpen={modalOpen}
          style={customStyles}
          shouldCloseOnOverlayClick={true}
        >
          <h1
            style={{
              position: 'fixed',
              right: -5,
              top: -38,
              fontSize: '2rem',
              cursor: 'pointer',
              background: 'rgba(255,255,255, 0.8)',
              color: 'black',
              borderRadius: '50%',
              height: 35,
              width: 35,
              fontWeight: 600,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              boxShadow: '0 0 20px rgba(0,0,0,0.2)',
            }}
            onClick={closeModal}
          >
            &times;
          </h1>
          <iframe
            width="568"
            height="315"
            src={`https://www.youtube.com/embed/${url}`}
            frameborder="0"
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
          />
        </Modal>
        <CardRealDesc>
          <h2>{title}</h2>
          <h4>{type}</h4>
          <div>
            <FaPlayCircle
              style={{ fontSize: '3rem' }}
              className="play"
              onClick={setOpen}
            />
          </div>
        </CardRealDesc>
      </CardRealContainer>
    </CardRealWrapper>
  )
}

CardReal.propTypes = {
  title: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  image: PropTypes.string.isRequired,
  url: PropTypes.string,
}

export default CardReal
