import React from 'react'
import styled from 'styled-components'
import Fade from 'react-reveal/Fade'

import Josie from '../images/josie.jpg'
import ImageBg from '../images/what-the-hex.png'

const TeamWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 2fr;
  min-height: 35rem;
  margin-top: -30px;
  /* background: url(${ImageBg}); */
  background: ghostwhite;


  @media (max-width: 1100px) {
    grid-template-columns: 1fr;
  }
`
const TeamLeft = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2rem;
  position: relative;
  margin-top: -30px;

  img {
    width: 32rem;
    border: 8px solid #f4f4f4;
    z-index: 10;
    border-radius: 50%;
    box-shadow: 0 4px 75px rgba(0, 0, 0, 0.2);

    @media (max-width: 1100px) {
      width: 20rem;
    }
  }

  @media (max-width: 1100px) {
    padding: 3rem 0rem 1rem 0rem;
    margin-top: 2rem;
  }
`
const TeamRight = styled.div`
  background: ghostwhite;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  margin-top: -40px;
  padding: 10rem 5rem 5rem 5rem;
  overflow: hidden;
  font-family: 'Nunito Sans', sans-serif;

  @media (max-width: 900px) {
    padding: 9rem 2rem 8rem 2rem;
    width: 100%;
  }

  h2 {
    font-size: 3.2rem;
    color: crimson;
    margin: 0;
    font-weight: 400;
    margin-top: -50px;

    @media (max-width: 900px) {
      text-align: center;
      line-height: 1.1;
      font-size: 2.8rem;
      margin-bottom: 0.5rem;
    }
  }

  h4 {
    margin: 0;
    font-size: 1.4rem;
    font-weight: 600;
    margin-bottom: 1.5rem;
    color: #292929;

    @media (max-width: 900px) {
      text-align: center;
    }
  }

  h4 span {
    @media (max-width: 900px) {
      display: none;
    }
  }

  p {
    font-size: 1.3rem;
    margin: 0;
    margin-bottom: 0.8rem;
    color: #292929;

    @media (max-width: 900px) {
      text-align: justify;
    }
  }

  span {
    font-weight: bold;
  }
`

const Team = () => {
  return (
    <TeamWrapper>
      <TeamLeft>
        <img src={Josie} alt="Josianne Vallieres" />
      </TeamLeft>
      <TeamRight>
        <Fade>
          <h2>
            Josiane <span>Vallières</span>
          </h2>
          <h4>
            <span>&mdash;</span> PDG
          </h4>
          <p>
            C'est une machine à idées, sa tête n'arrête jamais. De la gestion à
            la réalisation, elle a collaboré à plus d’une trentaine d’émissions
            de télévision au Québec telles que La Voix, 911, SQ, Occupation
            Double, Star Académie et Les Chefs. Avec plus de 12 ans d’expérience
            dans le domaine, elle a de solides fondations pour vous proposer des
            concepts ingénieux et de les concrétiser selon votre vision.
          </p>
          <p>
            L’équipe Walter est formée uniquement de professionnels issus du
            monde de la télévision. Notre grande expertise dans la production
            vidéo nous permet de vous livrer des contenus originaux et d’une
            qualité supérieure.
          </p>
        </Fade>
      </TeamRight>
    </TeamWrapper>
  )
}

export default Team
