import React from 'react'
import styled from 'styled-components'
import Fade from 'react-reveal/Fade'

import bg from '../images/back2.jpg'
import Card1Image from '../images/reals/Gary.jpg'
import Card2Image from '../images/reals/Pride.jpg'
import Card3Image from '../images/reals/Maison.jpg'
import Card4Image from '../images/reals/Info.jpg'
import Card5Image from '../images/reals/Amphora.jpg'
import Card6Image from '../images/reals/Guillaume.jpg'
import Youtube from '../images/youtube.png'

import CardReal from './CardReal'

const RealisationsWrapper = styled.div`
  background: linear-gradient(45deg, rgba(0, 0, 0, 0.75), rgba(0, 0, 0, 0.99)),
    url(${bg});
  background-attachment: fixed;
  background-size: cover;
  font-family: 'Nunito Sans', sans-serif;

  min-height: 50rem;
  margin-top: -50px;
  -webkit-clip-path: polygon(0 0, 100% 0%, 100% 95%, 0% 100%);
  clip-path: polygon(0 0, 100% 0%, 100% 95%, 0% 100%);
  padding: 8rem 2rem 2rem 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  .realisations__title {
    margin: 0;
    color: ghostwhite;
    text-align: center;
    font-size: 3.2rem;
    font-weight: 400;
    margin-bottom: 3.5rem;
    position: relative;
    display: inline-block;
    padding: 0.5rem 0.5rem;
    /* transform: skew(-15deg) rotate(-1deg); */
    border-bottom: 3px solid ghostwhite;

    /* &::after {
      content: '';
      display: flex;
      background: black;
      height: 100%;
      width: 100%;
      position: absolute;
      border-radius: 3px;
      left: 0;
      top: 0;
      z-index: -1;
    } */
    span {
      font-weight: bold;
    }

    @media (max-width: 900px) {
      font-size: 2.8rem;
    }
  }

  @media (max-width: 1200px) {
    -webkit-clip-path: none;
    clip-path: none;
  }
`

const RealisationsContainer = styled.div`
  /* max-width: 1200px; */
  margin: 0 auto;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
  justify-content: center;
  /* justify-items: center; */
  grid-auto-rows: 1fr;
  grid-gap: 40px;
  padding: 4rem 2rem;
  width: 100%;
  max-width: 80%;
  border-radius: 3px;
`

const RealisationsMore = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: ghostwhite;
  padding-bottom: 5rem;

  h3 {
    margin: 0;
    margin-left: 0.5rem;
    font-size: 1.2rem;

    @media (max-width: 900px) {
      text-align: center;
    }
  }

  img {
    width: 72px;
    margin-bottom: 1rem;
  }

  a {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: ghostwhite;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }
`

const Realisations = () => (
  <RealisationsWrapper>
    <h2 className="realisations__title">
      NOS
      <span> CRÉATIONS</span>
    </h2>

    <RealisationsContainer>
      <CardReal
        title="Gary Vaynerchuk"
        type="#ENTREVUE"
        image={Card1Image}
        url="oIwZf2-ijCk"
      />
      <CardReal
        title="Fierté Montréal Pride"
        type="#CAPSULE"
        image={Card2Image}
        url="jTJXnKDtwSQ"
      />
      <CardReal
        title="Maison Conteneur"
        type="#DOCUMENTAIRE"
        image={Card3Image}
        url="eIjcwVrByKo"
      />
      <CardReal
        title="Services Informatiques"
        type="#PUB"
        image={Card4Image}
        url="8aIdWl8qDSc"
      />
      <CardReal
        title="Amphora Life Insurance"
        type="#PRÉSENTATION"
        image={Card5Image}
        url="2yXu07T_W1Y"
      />
      <CardReal
        title="Guillaume L'Entrepreneur"
        type="#TEASER"
        image={Card6Image}
        url="9ezQrs9Aqps"
      />
    </RealisationsContainer>
    <Fade>
      <RealisationsMore>
        <a
          href="https://www.youtube.com/channel/UCklWeqiMsvxBWf2HUQ5bL7w/videos"
          target="_blank"
          rel="norel nofollower"
        >
          <img src={Youtube} alt="Youtube" />
          <h3>Pour en voir plus, visitez notre chaîne Youtube</h3>
        </a>
      </RealisationsMore>
    </Fade>
  </RealisationsWrapper>
)

export default Realisations
