import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'

const CardWrapper = styled.div`
  padding: 2rem;
  max-width: 35rem;
  border-radius: 3px;

  @media (max-width: 900px) {
    padding: 0.5rem;
  }
`

const CardContainer = styled.div`
  max-width: 35rem;
  display: grid;
  grid-template-columns: 100px 1fr;
  align-items: center;
  grid-gap: 40px;

  h2 {
    color: ghostwhite;
    font-size: 2rem;
    margin: 0;
    position: relative;
    display: inline-block;

    &::after {
      content: '';
      width: 120%;
      height: 4px;
      background: crimson;
      position: absolute;
      left: 0;
      bottom: -7px;

      @media (max-width: 900px) {
        width: 100%;
      }
    }
  }

  p {
    font-size: 1.3rem;
    color: ghostwhite;
    opacity: 0.9;
  }

  img {
    width: 100px;
    -webkit-filter: grayscale(100%);
    -moz-filter: grayscale(100%);
    -o-filter: grayscale(100%);
    -ms-filter: grayscale(100%);
    filter: grayscale(100%);

    @media (max-width: 900px) {
      /* display: none; */
      margin-bottom: 1rem;
      width: 75px;
    }
  }

  @media (max-width: 900px) {
    grid-template-columns: 1fr;
    grid-gap: 0;
    text-align: center;
  }
`

const Card = ({ title, desc, icon, hash }) => (
  <CardWrapper>
    <CardContainer>
      <div>
        <img src={icon} alt="icon" />
      </div>
      <div>
        <h2>{title}</h2>
        <p style={{ marginBottom: 5 }}>{desc}</p>
        <p style={{ marginTop: 0, fontWeight: 'bold' }}>{hash}</p>
      </div>
    </CardContainer>
  </CardWrapper>
)

Card.propTypes = {
  title: PropTypes.string.isRequired,
  desc: PropTypes.string.isRequired,
  icon: PropTypes.string.isRequired,
}

export default Card
