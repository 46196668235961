import React from 'react'
import styled from 'styled-components'
import { FaPhone, FaEnvelope } from 'react-icons/fa'

import Form from './Form'

import bg from '../images/back.jpg'

const ContactWrapper = styled.div`
  background: linear-gradient(45deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.8)),
    url(${bg});
  background-size: cover;
  min-height: 35rem;
  margin-top: -78px;
  display: grid;
  align-items: center;
  padding: 8rem 2rem;
  font-family: 'Nunito Sans', sans-serif;

  @media (max-width: 900px) {
    margin-top: 0;
    padding: 5rem 2rem 6rem 2rem;
  }
`

const ContactContainer = styled.div`
  /* background: ghostwhite; */
  color: ghostwhite;
  padding: 4rem 6rem;
  max-width: 1200px;
  margin: 0 auto;
  display: grid;
  grid-template-columns: 1fr 2fr;
  align-items: center;
  grid-column-gap: 80px;
  grid-row-gap: 50px;
  box-shadow: 0 0 55px rgba(0, 0, 0, 0.05);
  border-radius: 3px;

  h2 {
    font-size: 3.8rem;
    margin: 0;
    margin-bottom: 0.8rem;

    @media (max-width: 900px) {
      font-size: 3.2rem;
    }
  }

  p {
    font-size: 1.1rem;
    margin: 0;
    opacity: 0.9;
    margin-bottom: 0.5rem;
  }

  a {
    color: ghostwhite;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }

  @media (max-width: 900px) {
    padding: 1rem;
    grid-template-columns: 1fr;

    .contact__info {
      text-align: center;
    }
  }
`

const Contact = () => {
  return (
    <ContactWrapper>
      <ContactContainer>
        <div className="contact__info">
          <h2>Contact</h2>
          <p>
            <FaPhone style={{ marginRight: '0.6rem' }} />
            <a href="tel:514-850-7747">514.850.7747</a>
          </p>
          <p>
            <FaEnvelope style={{ marginRight: '0.6rem' }} />
            <a href="mailto:info@walterproduction.com">
              info@walterproduction.com
            </a>
          </p>
        </div>
        <div>
          <Form />
        </div>
      </ContactContainer>
    </ContactWrapper>
  )
}

export default Contact
