import React from 'react'
import makeCarousel from 'react-reveal/makeCarousel'
import Slide from 'react-reveal/Slide'
import styled from 'styled-components'

const TestimoniesWrapper = styled.div`
  background: black;
  width: 100%;
  display: flex;
  align-items: center;
  height: 13rem;

  @media (max-width: 1300px) {
    display: none;
  }
`

const Container = styled.div`
  position: relative;
  overflow: hidden;
  width: 80rem;
  margin: auto;
  color: ghostwhite;
  padding-top: 8rem;

  h1 {
    font-size: 1.5rem;
    margin-bottom: 0.5rem;
    font-weight: 400;
  }

  p {
    font-size: 1.1rem;
    margin: 0;
    color: crimson;
  }
`
const CarouselUI = ({ children }) => <Container>{children}</Container>
const Carousel = makeCarousel(CarouselUI)

const Testimonies = () => {
  return (
    <TestimoniesWrapper>
      <Carousel defaultWait={5000} maxTurns={300}>
        <Slide right>
          <div>
            <h1>
              “C'est un des meilleurs investissement que j'ai fait depuis
              longtemps! Je vous les recommande X 1000! Ce sont les meilleurs,
              ne cherchez plus! Des vraies pros et en plus ils sont cools!”
            </h1>
            <p>Vicky Girouard – PDG de Motivop</p>
          </div>
        </Slide>
        <Slide right>
          <div>
            <h1>
              “L’équipe de Walter comprend et intègre parfaitement la vision de
              ses clients, tout en se souciant réellement de leur offrir des
              solutions sur mesure et qui répondront à leurs besoins, le tout
              dans le plaisir et le respect! Merci Walter!”
            </h1>
            <p>
              François Laberge - Directeur des communications pour Fierté MTL
            </p>
          </div>
        </Slide>
        <Slide right>
          <div>
            <h1>
              "L'équipe de Walter Production a fait un travail remarquable en
              créant des vidéos accrocheuses et dynamiques, ce qui nous a offert
              une bonne visibilité et a attiré beaucoup de clients potentiels."
            </h1>
            <p>
              Sarah-Maude d'Aragon - Designer et Fondatrice du Projet Columbus
            </p>
          </div>
        </Slide>
      </Carousel>
    </TestimoniesWrapper>
  )
}

export default Testimonies
