import React from 'react'
import styled from 'styled-components'
import Fade from 'react-reveal/Fade'

const AboutWrapper = styled.div`
  padding: 9rem 4rem;
  background: ghostwhite;
  -webkit-clip-path: polygon(0 0, 100% 0%, 100% 95%, 0% 100%);
  clip-path: polygon(0 0, 100% 0%, 100% 95%, 0% 100%);
  min-height: 40rem;
  font-family: 'Nunito Sans', sans-serif;

  @media (max-width: 1200px) {
    -webkit-clip-path: none;
    clip-path: none;
    padding: 6rem 2rem;
  }
`

const AboutContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 80px;
  max-width: 1100px;
  margin: 0 auto;
  align-items: center;

  .content {
    @media (max-width: 1200px) {
      margin-bottom: 2rem;
      text-align: justify;
    }
  }

  h2 {
    margin: 0;
    /* margin-bottom: 0.2rem; */
    font-size: 3.2rem;
    font-weight: 400;
    color: crimson;

    @media (max-width: 900px) {
      text-align: center;
      line-height: 1.1;
      margin-bottom: 1.6rem;
    }
  }

  p {
    font-size: 1.2rem;
    position: relative;
    color: #292929;
  }

  span {
    font-weight: bold;
  }

  video {
    width: 100%;
    height: 100%;
    box-shadow: 0 0 35px rgba(0, 0, 0, 0.2);
    border: 7px solid rgba(0, 0, 0, 0.15);
    border-radius: 3px;

    @media (max-width: 1100px) {
      margin-bottom: 3.5rem;
    }
  }

  @media (max-width: 1200px) {
    grid-template-columns: 1fr;
    justify-items: center;
    align-items: flex-start;
  }
`

const About = () => {
  return (
    <AboutWrapper>
      <AboutContainer>
        <div className="content">
          <Fade>
            <h2>
              Qui est <span>Walter</span>?
            </h2>
            <p style={{ marginTop: '0.6rem' }}>
              Chaque entreprise est différente à sa façon. Chaque être humain
              aussi. Chez Walter, on croit que ce qui fait le coeur d’une
              compagnie est l’humain derrière celle-ci.
            </p>
            <p>
              Notre mission est de faire ressortir les couleurs de votre
              entreprise en créant des contenus vidéos uniques et à votre image
              pour un maximum de visibilité sur le web.
            </p>
            <p>
              Les publicités à la télévision, à la radio et dans les journaux
              rejoignent de moins en moins vos clients car l’attention de la
              population est désormais tournée vers les cellulaires. Résultat?
              Le vidéo marketing explose sur les réseaux sociaux. C’est un
              levier commercial ultra puissant et il procure un important retour
              sur investissement.
            </p>
          </Fade>
        </div>
        <div>
          <video
            // poster="https://s3-us-west-2.amazonaws.com/s.cdpn.io/4273/polina.jpg"
            playsInline
            loop
            controls
          >
            {/*<source src={VideoWebm} type="video/webm" /> */}

            <source
              src="https://res.cloudinary.com/dl9mctxsb/video/upload/v1547433747/video-intro.mp4"
              type="video/mp4"
            />
          </video>
        </div>
      </AboutContainer>
    </AboutWrapper>
  )
}

export default About
