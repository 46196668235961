import React from 'react'
import styled from 'styled-components'
import Fade from 'react-reveal/Fade'

import Client1 from '../images/clients/MOTIVOP_logo_V_Blanc.png'
import Client2 from '../images/clients/GUILLAUME BAREIL.png'
import Client3 from '../images/clients/EOS CANADA.png'
import Client4 from '../images/clients/pulmo.jpg'
import Client5 from '../images/clients/corporate.png'
import Client6 from '../images/clients/AMPHORA FINANCIAL GROUP.png'
import Client7 from '../images/clients/FESTIVAL_FIERTE_MTL.png'
import Client8 from '../images/clients/FUJI.png'

const ClientsWrapper = styled.div`
  background: #000;
  box-shadow: 2px 2px 15px rgba(0, 0, 0, 0.5);
  border-top: 7px solid rgba(255, 255, 255, 0.05);
  padding: 4rem 2rem;

  /* @media (max-width: 900px) {
    display: none;
  } */
`

const ClientsContainer = styled.div`
  min-height: 40rem;
  padding: 2rem;
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: center;
  h2 {
    margin: 0;
    color: ghostwhite;
    font-size: 2.8rem;
    text-align: center;
    margin-bottom: 3.4rem;
    font-weight: 300;
  }

  span {
    font-weight: bold;
  }

  img {
    max-width: 225px;
    margin: 0 1rem;
    margin-bottom: 6em;

    -webkit-filter: grayscale(100%);
    -moz-filter: grayscale(100%);
    -o-filter: grayscale(100%);
    -ms-filter: grayscale(100%);
    filter: grayscale(100%);
    transition: all 0.3s ease-in-out;

    &:hover {
      -webkit-filter: grayscale(0%);
      -moz-filter: grayscale(0%);
      -o-filter: grayscale(0%);
      -ms-filter: grayscale(0%);
      filter: grayscale(0%);
    }

    @media (max-width: 900px) {
      max-width: 175px;
    }
  }
`

const ClientsLogos = styled.div`
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  min-width: 100%;
  flex-wrap: wrap;
  & > * {
    margin-bottom: 2em;
  }

  .clients__desktop {
    display: none;
  }

  @media (min-width: 900px) {
    .clients__desktop {
      display: flex;
      align-items: center;
      justify-content: space-evenly;
      min-width: 100%;
    }
  }
`

const Clients = () => {
  return (
    <ClientsWrapper>
      <ClientsContainer>
        <Fade>
          <h2>
            Quelques <span>Clients</span>
          </h2>
          <ClientsLogos>
            <img src={Client1} alt="Logo - Motivop" />
            <img
              src={Client7}
              alt="Logo - Fierte Gai MTL"
              style={{ width: 130 }}
            />
            <img src={Client5} alt="Logo - Corporate Stays" />
            <img src={Client4} alt="Logo - Association Pulmonaire Quebec" />
            <div className="clients__desktop">
              <img
                src={Client3}
                alt="Logo - EOS Canada"
                style={{ width: 120 }}
              />
              <img src={Client8} alt="Logo - Fuji" style={{ width: 120 }} />
              <img src={Client6} alt="Logo - Amphora" />
              <img src={Client2} alt="Logo - Guillaume Bareil" />
            </div>
          </ClientsLogos>
        </Fade>
      </ClientsContainer>
    </ClientsWrapper>
  )
}

export default Clients
